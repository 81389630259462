<template>
  <v-container id="container" class="ma-0 pa-0" style="width: 100vw;">

    <!-- home image -->
    <div id="welcome"></div>

    <!-- pages -->
    <div :class="contentThemeClass" class="d-flex flex-wrap justify-center align-stretch">
      <v-card outlined class="ma-2" style="max-width: 400px"
              v-for="item in items" v-bind:key="item.title">
        <v-card-title>{{ item.title }}</v-card-title>
        <v-card-text>{{ item.text }}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" style="text-transform: unset" class="pa-3"
                 @click="$router.push(item.route)">
            {{ item.action }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- discord -->
    <div :class="contentThemeClass">
      <div style="margin: 0 auto; width:300px; text-align: center">
        <b>MASZ PYTANIA?</b><br>
        Dołącz do naszego serwera Discord<br>
        i skontaktuj się z nami.<br>
        <v-btn class="mt-3" color="primary" @click="openURL('https://discord.gg/mpsye2z')">
          Dołącz
          <v-icon class="ml-3">mdi-discord</v-icon>
        </v-btn>
      </div>
    </div>

    <!-- partners -->
    <div :class="contentThemeClass" class="d-flex flex-wrap justify-center align-stretch">
      <v-img contain height="80" max-width="290" :src="website.img"
             style="cursor: pointer" class="mt-1 mb-1" @click='openURL(website.link)'
             v-for="website in websites" v-bind:key="website.link"/>
    </div>

    <!-- theme -->
    <div :class="contentThemeClass">
      <v-btn-toggle mandatory tile group v-model="theme" color="primary darken-2"
                    class="d-flex flex-no-wrap justify-center align-stretch">
        <v-btn value="auto" @click="updateTheme('auto')">
          <v-icon>mdi-lightbulb-auto-outline</v-icon>
        </v-btn>
        <v-btn value="light" @click="updateTheme('light')">
          <v-icon>mdi-weather-sunny</v-icon>
        </v-btn>
        <v-btn value="dark" @click="updateTheme('dark')">
          <v-icon>mdi-weather-night</v-icon>
        </v-btn>
      </v-btn-toggle>
      <div style="margin: 0 auto; width: 300px; text-align: center">
        Motyw: <b>{{ theme === 'auto' ? 'systemowy' : (theme === 'light' ? 'jasny' : 'ciemny') }}</b>
      </div>
    </div>

  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    items: [{
      title: 'ZAWODY',
      text: 'Zobacz listę zakończonych zawodów. Znajdziesz tu wszystkie wyniki i informacje o minionych zawodach.',
      action: 'Zobacz',
      route: '/competitions'
    }, {
      title: 'RANKINGI',
      text: 'Brałeś/aś udział w naszych zawodach. Sprawdź swoją pozycję w ogólnopolskim rankingu.',
      action: 'Sprawdź',
      route: '/rankings'
    }, {
      title: 'REKORDY',
      text: 'Znajdziesz tu najlepsze czasy osiągane przez zawodników.',
      action: 'Otwórz',
      route: '/records'
    }, {
      title: 'ZAWODNICY',
      text: 'Brałeś/aś udział w zawodach i interesuje Cię Twoja historia speedcubingowa. Zajrzyj tutaj.',
      action: 'Zajrzyj',
      route: '/competitors'
    }, {
      title: 'KLASYFIKACJE',
      text: 'Sprawdź swoją pozycję w klasyfikacjach Pucharu Polski Online 2024.',
      action: 'Sprawdź',
      route: '/series/PPO2024'
    }],
    websites: [{
      img: 'https://www.rubiart.pl/img/logo-transparent.png?v2',
      link: 'https://www.rubiart.pl/'
    }, /*{
      img: 'https://www.speedcubing.pl/content/logo-pss-poziome.jpg',
      link: 'https://www.speedcubing.pl/'
    }, */{
      img: 'https://www.freepnglogos.com/uploads/rubiks-cube-png/rubiks-cube-how-solve-the-rubik-cube-stage-blog-rubik-official-website-19.png',
      link: 'https://www.rubiks.com/'
    }],
    theme: 'auto',
  }),
  mounted() {
    let theme = localStorage.getItem('theme');
    if (theme != null) this.theme = theme;
  },
  methods: {
    openURL(url) {
      window.open(url, '_blank');
    },
    updateTheme(theme) {
      if (['dark', 'light'].includes(theme))
        localStorage.setItem('theme', theme);
      else localStorage.removeItem('theme');
      this.$vuetify.theme.dark = (theme === 'dark' || (theme === 'auto' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches));
    }
  },
  computed: {
    contentThemeClass() {
      return this.$vuetify.theme.dark ? 'content-dark' : 'content-light';
    }
  }
};
</script>

<style scoped>
#welcome {
  background-image: url('/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  /* height: calc(100vh - 60px); */
  height: 150px;
}

.content-light {
  padding: 32px;
  width: 100vw;
  border-top: 1px solid #e0e0e0;
}

.content-dark {
  padding: 32px;
  width: 100vw;
  border-top: 1px solid #2f2f2f;
}
</style>
